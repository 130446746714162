<template>
  <div class="footer" v-if="ispc">
    <div class="header_title">
      <img src="@/assets/img/logo/logoTop1.png">
    </div>
    <div class="center_content">
      <div class="one">
        <div>Contact Number</div>
        <p>+0065 97941186</p>
      </div>
      <div class="one">
        <div>Business Cooperation</div>
        <p>joerao@chasingai.com</p>
      </div>
      <div class="one">
        <div>Channel Partnership</div>
        <p>alexliu@chasingai.com</p>
      </div>
      <div class="selecten">
        <div> 
          <img v-if="fcshow" src="@/assets/img/logo/facebook.png" @mouseover="hoverfc" @mouseout="hoverfc">
          <img v-else src="@/assets/img/logo/huifacebook.png" @mouseover="hoverfc" @mouseout="hoverfc">

          <img v-if="inshow" style="cursor:pointer;" src="@/assets/img/logo/in.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">
          <img v-else style="cursor:pointer;" src="@/assets/img/logo/huiin.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">

          <img v-if="wxshow" src="@/assets/img/logo/wechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
          <img v-else src="@/assets/img/logo/huiwechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
        </div>
        <el-select
          v-model="value"
        >
          <el-option
            v-for="item in options"
            :key="item.index"
            :label="item.label"
            :value="item.index"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="footer_title">
      <span >Copyright © 2022, CHASING AI PTE. LTD.</span>
      <span>Privacy Policy</span>
      <span>Terms & Conditions</span>
    </div>
  </div>
  <div class="footer footer1" v-else>
    <div class="center_content">
      <div class="one">
        <div>Contact Number</div>
        <p>+0065 97941186</p>
      </div>
      <div class="one">
        <div>Business Cooperation</div>
        <p>joerao@chasingai.com</p>
      </div>
    </div>
    <div class="center_content">
      <div class="one">
        <div>Channel Partnership</div>
        <p>alexliu@chasingai.com</p>
      </div>
      <div class="selecten">
        <div> 
          <img v-if="fcshow" src="@/assets/img/logo/facebook.png" @mouseover="hoverfc" @mouseout="hoverfc">
          <img v-else src="@/assets/img/logo/huifacebook.png" @mouseover="hoverfc" @mouseout="hoverfc">

          <img v-if="inshow" style="cursor:pointer;" src="@/assets/img/logo/in.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">
          <img v-else style="cursor:pointer;" src="@/assets/img/logo/huiin.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">

          <img v-if="wxshow" src="@/assets/img/logo/wechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
          <img v-else src="@/assets/img/logo/huiwechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
        </div>
        <el-select
          v-model="value"
        >
          <el-option
            v-for="item in options"
            :key="item.index"
            :label="item.label"
            :value="item.index"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="footer_title">
      <span >Copyright © 2022, CHASING AI PTE. LTD.</span>
      <span>Privacy Policy</span>
      <span>Terms & Conditions</span>
    </div>
  </div>
</template>

<script>

export default {
  name:'footers',
  data(){
    return {
      value: 1,
      ispc:true,
      fcshow:true,
      inshow:true,
      wxshow:true,
      options:[
        {
          index: 1,
          label:'English - En'
        }
      ]
    }
  },
  mounted(){
    let w = document.documentElement.clientWidth || document.body.clientWidth;
    if(w <= 900){
      this.ispc = false
    }else{
      this.ispc = true
    }
  },
  methods:{
    jumpins(){
      window.open('https://www.linkedin.com/company/chasing-ai-pte-ltd/')
    },
    hoverfc(){
      this.fcshow = !this.fcshow
    },
    hoverin(){
      this.inshow = !this.inshow
    },
    hoverwx(){
      this.wxshow = !this.wxshow
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height:400px;
  background: #0D8AF2;
  padding:80px 220px;
  position: relative;
  .header_title{
    height:70px;
    margin-bottom:50px;
    img{
      height:100%;
    }
  }
  .center_content{
    display: flex;
    justify-content: space-between;
    padding-left:20px;
    .one{
      color: #fff;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        color: #FFFFFF;
        margin-bottom:30px;
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
  }
  .selecten{
    img{
      height:33px;
      width:33px;
      margin-right:20px;
    }
    .el-select{
      margin-top:20px;
      /deep/.el-input__inner{
        width:210px;
        border-radius: 10px;
        background: url('../assets/img/logo/diqiu.png') #0D8AF2 no-repeat;
        background-size:23px 23px;
        background-position:7px 9px;
        padding-left:45px;
        color:#fff;
        border: 1px solid #B3E5FC;
      }
      /deep/.el-select__caret{
        color:#B3E5FC;
      }
    }
  }
  .footer_title{
    position:absolute;
    bottom:25px;
    color:rgba(255, 255, 255, 0.7);
    font-size:14px;
    span:nth-child(1){
      padding-right:20px;
      padding-left:20px;
      border-right:1px solid rgba(255, 255, 255, 0.5);;
    }
    span:nth-child(2){
      padding-right:20px;
      padding-left:20px;
      border-right:1px solid rgba(255, 255, 255, 0.5);;
    }
    span:nth-child(3){
      padding-right:20px;
      padding-left:20px;
    }
  }
}

.footer1{
  height:560px;
  .center_content{
    display: flex;
    justify-content: space-between;
    padding-left:20px;
    margin-bottom:70px; 
    .one{
      width: 600px;
      color: #fff;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 46px;
        color: #FFFFFF;
        margin-bottom:30px;
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        color: #FFFFFF;
      }
    }
  }
  .selecten{
    width:600px;
    img{
      height:56px;
      width:56px;
      margin-right:50px;
    }
    .el-select{
      margin-top:10px;
      height:70px;
      /deep/.el-input__inner{
        width:400px;
        height:70px;
        border-radius: 10px;
        background: url('../assets/img/logo/diqiu.png') #0D8AF2 no-repeat;
        background-size:50px 50px;
        background-position:10px 12px;
        padding-left:85px;
        color:#fff;
        font-size: 42px;
        border: 1px solid #B3E5FC;
      }
      /deep/.el-select__caret{
        color:#B3E5FC;
      }
    }
  }
  .footer_title{
    position:absolute;
    bottom:25px;
    color:rgba(255, 255, 255, 0.7);
    font-size:35px;
    span:nth-child(1){
      padding-right:20px;
      padding-left:20px;
      border-right:1px solid rgba(255, 255, 255, 0.5);;
    }
    span:nth-child(2){
      padding-right:20px;
      padding-left:20px;
      border-right:1px solid rgba(255, 255, 255, 0.5);;
    }
    span:nth-child(3){
      padding-right:20px;
      padding-left:20px;
    }
  }
}

</style>