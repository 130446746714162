import axios from 'axios'
import Vue from "vue";

function request(config) {
    const service = axios.create({
        baseURL: process.env.VUE_APP_BASE_API
    })

    service.interceptors.request.use(config => {
        // 设置完config以后必须return 出去, 在这里可以加请求头，或者加断开请求的拦截（百度 `new axios.CancelToken`）
        const token = window.sessionStorage.getItem('token')

        token !== null && (config.headers.Authorization = token)
        return config

    }, err => {
        return Promise.reject(err.message)
    })

    service.interceptors.response.use(res => {
        res = res.data
        // 设置完res以后必须return 出去
        if (res.code == 200) {
            return res
        } else {
            if(res.code === undefined) {
                Vue.prototype.$message.error(res.errors)
                return Promise.reject(res.errors)
            }
            Vue.prototype.$message.error(res.msg)
            return Promise.reject(res.msg)
        }
    }, err => {
        return Promise.reject(err.message)
    })

    return service(config)
}

export default request
