<template>
  <div class="freeTrial">
    <div class="center">
      <div class="centerbox" v-if="ispc">
        <div class="leftimg">
          <div class="lefttext">
            <img src="@/assets/img/logo/logoTop1.png">
            <p>Focus on enterprise digital workflow reengineering</p>
          </div>
        </div>
        <div class="rightform">
          <div class="backbtn">
            <el-button type="text" @click="backs">
              <span>
                <img src="@/assets/img/logo/back.png">
                back
              </span>
            </el-button>
          </div>
          <div class="freeform">

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <p class="formhead">Free Trial</p>
              <el-form-item prop="phoneNum">
                <el-input v-model="ruleForm.phoneNum" placeholder="Phone Number"></el-input>
              </el-form-item>
              <el-form-item prop="userName">
                <el-input v-model="ruleForm.userName" placeholder="Name"></el-input>
              </el-form-item>
              <el-form-item prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder="Company Name"></el-input>
              </el-form-item>
              <el-form-item prop="terms">
                <el-checkbox 
                  v-model="ruleForm.terms" 
                  class="redcheck" 
                  ref="redcheck" 
                  @change="blackcheck"
                ></el-checkbox>
                <div class="ptitle">
                  <p>I have read and accept the Terms of </p><br>
                  <p style="color:#01579B;cursor:pointer">Service & Privacy Policy *</p>
                </div>
              </el-form-item>
              <el-form-item class="submit">
                <el-button @click="submitform('ruleForm')">submit</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 移动 -->
      <div class="centerbox1" v-else>
        <!-- <div class="topimg">
          <div class="lefttext">
            <p>Focus on enterprise digital workflow reengineering</p>
            <img src="@/assets/img/logo/logoTop1.png">
          </div>
        </div> -->
        <div class="rightform">
          <div class="backbtn">
            <el-button type="text" @click="backs">
              <span>
                <img src="@/assets/img/logo/back.png">
                back
              </span>
            </el-button>
          </div>
          <div class="freeform">

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <p class="formhead">Free Trial</p>
              <el-form-item prop="phoneNum">
                <el-input v-model="ruleForm.phoneNum" placeholder="Phone Number"></el-input>
              </el-form-item>
              <el-form-item prop="userName">
                <el-input v-model="ruleForm.userName" placeholder="Name"></el-input>
              </el-form-item>
              <el-form-item prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder="Company Name"></el-input>
              </el-form-item>
              <el-form-item prop="terms" class="xieyi">
                <el-checkbox 
                  v-model="ruleForm.terms" 
                  class="redcheck" 
                  ref="redcheck" 
                  @change="blackcheck"
                ></el-checkbox>
                <div class="ptitle ptitle1">
                  <p>I have read and accept the Terms of </p><br>
                  <p style="color:#01579B;cursor:pointer">Service & Privacy Policy *</p>
                </div>
              </el-form-item>
              <el-form-item class="submit">
                <el-button @click="submitform('ruleForm')">submit</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <footers v-if="ispc"></footers>
  </div>
</template>

<script>
import footers from "@/components/footers.vue"
import { submit } from "@/api/test.js";
export default {
  name: "freeTrial",
  data() {
    return {
      ispc:true,
      ruleForm:{
        userName: "",
        phoneNum: "",
        companyName: "",
        terms:false,
      },
      rules: {
        phoneNum: {required: true, message: '* Please input phone number', trigger: 'blur'},
        userName: {required: true, message: '* Please input user name', trigger: 'blur'},
        companyName:{required: true, message: '* Please input company name', trigger: 'blur'},
      },
    };
  },
  components:{
    footers,
  },
  mounted(){
    this.pmwidth()
  },
  methods: {
    backs(){
      this.$router.push("/firstPage")
    },
    pmwidth(){
      let w = document.documentElement.clientWidth || document.body.clientWidth;
      if(w <= 900){
        this.ispc = false
      }else{
        this.ispc = true
      }
    },
    submitform(formName){
      if(this.ruleForm.terms == false){
        this.$refs.redcheck.$el.style.border = '1px solid red'
      }
      this.$refs[formName].validate((valid) => {
        if (valid && this.ruleForm.terms != false) {
          submit(this.ruleForm).then((res) => {
            if (res.code != 200) return;
            this.ruleForm = {
              userName: "",
              phoneNum: "",
              companyName: "",
              terms:false,
            }
            // ElMessage.success(msg)
            this.$router.push('/success')
          });
        }
      });
    },

    blackcheck(){
      this.$refs.redcheck.$el.style.border = '1px solid black'
    }
  },
};
</script>


<style scoped lang="scss">
.freeTrial{
  width: 100%;
  height:100vh;
  background: #0D8AF2;
  // background: url("../assets/img/logo/freeback.png") no-repeat;
  // background-size: 100% 100%;
  .center{
    width:100%; 
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .centerbox1{
      width:80%;
      // height:90%;
      background: #0D8AF2;
      filter: drop-shadow(0px 3px 49px rgba(0, 0, 0, 0.25));
      border-radius:30px;
      .topimg{
        width:100%;
        height:40%;
        display: flex;
        align-items: center;
        position:relative;
        .lefttext{
          height:30%;
          width:100%;
          left:50px;
          display: flex;
          justify-content: center;
          flex-direction: column-reverse;
          align-items: center;
          img{
            height:60%;
            width:60%;
          }
          p{
            text-align: center;
            font-family: 'Montserrat-light';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            color: #FFFFFF;
            margin-top:30px;
          }
        }
      }
      .rightform{
        width:100%;
        // height:100%;
        background:#fff;
        float:left;
        border-radius:30px 30px 30px 0;
        position:relative;
        .backbtn{
          width:100%;
          // position:absolute;
          // right:10px;
          padding-right:30px;
          padding-top:10px;
          .el-button{
            float:left;
            margin-left:36px;
            margin-top:30px;
            span{
              font-family: 'Montserrat-light';
              font-style: normal;
              font-weight: 700;
              font-size: 60px;
              color: rgba(0, 0, 0, 0.87);
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width:53px;
                height:53px;
                margin-right:10px;
              }
            }
          }
        }
        .freeform{
          height:100%;
          width:100%;
          display: flex;
          justify-content: center;
          .el-form{
            width:80%;
            height:100%;
            margin-top:10%;
            .formhead{
              font-size: 100px;
            }
            .el-form-item{
              margin-bottom:80px;
            }
            /deep/.el-input__inner{
              font-size: 80px;
              height:250px;
              border-top:0;
              border-left:0;
              border-right:0;
              border-radius:0;
              // background: rgba(0, 0, 0, 0.05);
            }
            /deep/.el-checkbox{
              display: flex;
              align-items: center;
              width:80px;
              float:left;
            }
            /deep/.el-checkbox__input{
              width:80px;
              height:80px;
            }
            .submit{
              height:160px;
              /deep/.el-form-item__content{
                height:100%;
              }
              .el-button{
                width: 100%;
                height:100%;
                background: #FFA000;
                border-radius:40px;
                font-family: 'Montserrat-light';
                font-style: normal;
                font-weight: 600;
                font-size: 60px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
              }
            }
            .xieyi{
              padding-top:100px;
              margin-bottom:180px;
            }
          }
        }
      }
      /deep/.el-checkbox__inner{
        width:80px !important;
        height:80px !important;
        border:0;
        // box-sizing:border-box;
      }

      /deep/.el-checkbox__inner::after{
        width:50px;
        height:60px;
        left:12px;
      }

       /deep/input::-webkit-input-placeholder{
        font-size:60px; 
        font-weight:700;  
       }

       /deep/.el-form-item__error{
          margin-top:5px;
          font-size:55px !important;
        }

    }
    .centerbox{
      width:80%;
      height:90%;
      background: url('../assets/img/logo/formbk.png') #F2F9FF no-repeat;
      background-size: 100% 85%;
      filter: drop-shadow(0px 3px 49px rgba(0, 0, 0, 0.25));
      border-radius:30px;
      .leftimg{
        width:42%;
        height:100%;
        float:left;
        position:relative;
        .lefttext{
          height:18%;
          width:80%;
          position:absolute;
          top:35%;
          left:16%;
          img{
            height:50%;
            width:60%;
          }
          p{
            font-family: 'Montserrat-light';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            color: #FFFFFF;
            margin-top:30px;
          }
        }
      }
      .rightform{
        width:58%;
        height:100%;
        background:#fff;
        float:left;
        border-radius:30px 30px 30px 0;
        position:relative;
        .backbtn{
          width:100%;
          // position:absolute;
          // right:10px;
          padding-right:30px;
          padding-top:10px;
          .el-button{
            float:right;
            span{
              font-family: 'Montserrat-light';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              color: rgba(0, 0, 0, 0.87);
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width:23px;
                height:23px;
                margin-right:10px;
              }
            }
          }
        }
        .freeform{
          height:100%;
          width:100%;
          display: flex;
          justify-content: center;
          .el-form{
            width:80%;
            height:50%;
            margin-top:10%;
            .el-form-item{
              margin-bottom:40px;
            }
            /deep/.el-input__inner{
              height:55px;
              border-top:0;
              border-left:0;
              border-right:0;
              border-radius:0;
              background: rgba(0, 0, 0, 0.05);
            }
            /deep/.el-checkbox{
              display: flex;
              align-items: center;
              width:30px;
              float:left;
            }
            /deep/.el-checkbox__input{
              width:30px;
              height:30px;
            }
            .submit{
              height:55px;
              /deep/.el-form-item__content{
                height:100%;
              }
              .el-button{
                width: 100%;
                height:100%;
                background: #FFA000;
                border-radius:10px;
                font-family: 'Montserrat-light';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}

/deep/.el-checkbox__inner{
  width:30px !important;
  height:30px !important;
  border:0;
  // box-sizing:border-box;
}

/deep/.el-checkbox__inner::after{
  width:10px;
  height:20px;
  left:9px;
}

.redcheck{
  border:1px solid #000;
}

/deep/.el-form-item__error{
  margin-top:5px;
  font-size:15px;
}


.ptitle{
  margin-left:20px;
  float:left;
  line-height:16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.ptitle1{
  font-size: 50px;
  line-height:50px;
}


/deep/input::-webkit-input-placeholder{
  -webkit-text-fill-color: rgba(0, 0, 0, 0.42);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.formhead{
  text-align: center;
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom:50px;
}
</style>